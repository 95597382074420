<template>
<div class="linkList" v-if="links.length">
  <hr class="uk-margin">
  <ul class="uk-list">
    <li v-for="entry in links" :key="entry.id">
      <a :href="entry.url" :title="entry.text" :targt="'_' + entry.target" v-html="entry.text"></a>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'LinkList',
  props: {
    links: Array
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >

</style>
