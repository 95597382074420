<template>
<div class="">

  <button class="vm-modal__close" @click="$emit('close')">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>

  <button class="c-va_modal__expand" @click="requestFullscreen()">
    <span uk-icon="icon: expand; ratio: 1.5"></span>
  </button>

  <div class="uk-background-default uk-box-shadow-medium">

    <VueSlickCarousel v-bind=" slickSettings" ref="carousel">
      <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
    </VueSlickCarousel>

  </div>



</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Slide from './Slide.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'ModalMedia',
  data: function() {
    return {
      slickSettings: {
        "dots": true,
        "arrows": true
      }
    };
  },
  props: {
    title: String,
    subtitle: String,
    slides: Array
  },
  components: {
    VueSlickCarousel,
    Slide,

  },
  methods: {
    requestFullscreen() {
      this.$fullScreenEl = this.$refs.carousel.$el;
      this.$fullScreenEl.requestFullscreen();

    },
    onFullscreenChange() {

      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement && !document.fullScreen) {
        // leave
        // remove fullscreen btns
        this.$fsShrinkBtn.remove()
        this.$fsNextBtn.remove()
        this.$fsPrevBtn.remove()
      } else {
        //enter
        // add shrink button
        this.$fsShrinkBtn = document.createElement('button')
        this.$fsShrinkBtn.classList.add('c-va_modal__fs-shrink')
        this.$fsShrinkBtn.innerHTML = ` <span uk-icon="icon: shrink; ratio: 1.5"></span>`;
        this.$fsShrinkBtn.addEventListener('click', () => {
          document.exitFullscreen();
        });
        this.$fullScreenEl.append(this.$fsShrinkBtn);

        // add next btn
        this.$fsNextBtn = document.createElement('button')
        this.$fsNextBtn.classList.add('c-va_modal__fs-next')
        this.$fsNextBtn.innerHTML = ` <span uk-icon="icon:  chevron-right; ratio: 1.5"></span>`;
        this.$fsNextBtn.addEventListener('click', () => {
          this.$refs.carousel.next()
        });
        this.$fullScreenEl.append(this.$fsNextBtn);

        // add prev btn
        this.$fsPrevBtn = document.createElement('button')
        this.$fsPrevBtn.classList.add('c-va_modal__fs-prev')
        this.$fsPrevBtn.innerHTML = ` <span uk-icon="icon:  chevron-left; ratio: 1.5"></span>`;
        this.$fsPrevBtn.addEventListener('click', () => {
          this.$refs.carousel.prev()
        });
        this.$fullScreenEl.append(this.$fsPrevBtn);


      }
    }

  },
  created() {

    document.addEventListener("fullscreenchange", this.onFullscreenChange, false);
    document.addEventListener("mozfullscreenchange", this.onFullscreenChange, false);
    document.addEventListener("webkitfullscreenchange", this.onFullscreenChange, false);
    document.addEventListener("msfullscreenchange", this.onFullscreenChange, false);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
