<template>
<div class="title">
  <h1>{{strings.app_title}}</h1>
  <div class="">

    <transition name="fade" enter-active-class="fadeInRight" leave-active-class="fadeOutRight">
      <div class="l-title" v-if="locationTitle">
        {{locationTitle}}
      </div>
    </transition>

    <transition name="fade" enter-active-class="fadeInRight" leave-active-class="fadeOutRight">
      <button class="uk-margin-top back-btn" v-if="showBackBtn" @click="goBack()" style="transition-delay: .5s">
        <i uk-icon="chevron-left"></i> {{strings.back}}</button>
    </transition>

  </div>
</div>
</template>
<script>
import {
  bus
} from '../main';

export default {
  name: 'Title',
  data() {
    return {
      strings: this._vaData.strings,
      locationTitle: false
    }
  },
  props: {
    showBackBtn: Boolean
  },
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  methods: {
    goBack: () => {
      bus.$emit('go_back');
    }
  },
  created() {

    bus.$on('location_reached', (id) => {
      this.locationTitle = this._vaData.locations.find(l => l.id === id).title
    })

    bus.$on('location_left', () => {
      this.locationTitle = false;
    })

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";

.title {
    // @extend .uk-padding;
    margin-top: -.5em;
    @media (max-width: $breakpoint-large) {
        // static header  on small portrait devices (smartphones)
        display: none;
    }
}

h1 {
    text-transform: uppercase;
    color: var(--tertiary-color);
    margin: 0;
    font-size: 1.8em;
}

.l-title {
    // @extend .uk-button;
    // @extend .uk-button-small;
    // background: transparent;
    overflow: hidden;
    font-size: 1.4em;
    color: var(--primary-color);
    text-transform: uppercase;
}

h2 {
    margin: 0;
    font-size: 1.4em;
    text-transform: uppercase;
    // line-height: 1;
}

.back-btn {
    @extend .uk-button;
    @extend .uk-button-tertiary;
    @extend .uk-button-small;
    padding-left: 4px;
    margin-right: 20px;
    text-transform: uppercase;
    display: flex;

    align-items: center;
    // line-height: 1;
    white-space: nowrap;

    i {
        margin-right: 5px;
    };

}
</style>
