<template>
<div>
  <div>
    <button class="uk-button uk-button-primary" type="button">
      <span uk-icon="icon: world"></span><span>{{currentLanguage.name}}</span>
    </button>
  </div>
  <div uk-dropdown="mode: click">
    <ul class="uk-nav uk-dropdown-nav">
      <li v-for="language in languages" :key="language.id" :class="(currentLanguage === language.id) ? 'uk-active' : '' ">
        <a :href="'?lang=' + language.id">{{language.name}}</a>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  props: {

  },
  computed: {
    currentLanguage: function() {
      return {
        id: this._vaData.currentLanguage,
        name: this.languages.find(l => l.id === this._vaData.currentLanguage).name
      }
    }
  },
  data: function() {
    return {

      languages: [{
          name: 'English',
          id: 'en'
        },
        // {
        //   name: '日本語',
        //   id: 'jp'
        // },
        {
          name: '中国人',
          id: 'cn'
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
[uk-dropdown] {
    // border-radius: 3px;
}

.uk-button {
    padding: 0 15px;

    span:first-child {
        margin-right: 15px;
    }
}
</style>
