<template>
<div class="meta-links">
  <div uk-grid class="uk-grid-small">
    <a v-for="link in links" :key="link.id" class="" :href="link.url" :target="'_' + link.target">{{link.text}}</a>
  </div>
</div>
</template>
<script>
export default {
  name: 'MetaLinks',
  data: function() {
    return {
      links: this._vaData.strings.meta_links
    }
  },
  props: {

  }
}
</script>
<style lang="scss">
.meta-links {
    a {
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: flex-end;
        font-size: 0.7em;
        opacity: 0.5;

        &:hover {

            opacity: 1;
            text-decoration: none;
        }
    }
}
</style>
