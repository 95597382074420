<template>
<div class="videothing">
  <!-- here -->
</div>
</template>

<script>
import VideoThing from 'videoThing';
import "../../../node_modules/videoThing/dist/videoThing.css";
import {
  bus
} from '../../main';

export default {
  name: 'VideoThing',
  props: {
    settings: Object
  },
  created() {
    bus.$on('go_back', () => {
      this.videoThing.goBack();
    })
    bus.$on('show_hotspots', () => {
      this.videoThing.showHotspots()
    })
    bus.$on('hide_hotspots', () => {
      this.videoThing.hideHotspots()
    })
    bus.$on('show_navigation', () => {
      this.videoThing.showNavigation()
    })
    bus.$on('hide_navigation', () => {
      this.videoThing.hideNavigation()
    })
    bus.$on('show_menus', () => {
      this.videoThing.showMenus()
    })
    bus.$on('hide_menus', () => {
      this.videoThing.hideMenus()
    })
    bus.$on('go_to_location', (id) => {
      this.videoThing.goToLocation(id)
    })
  },
  methods: {},
  mounted() {

    const elem = document.querySelector('.videothing');
    this.videoThing = new VideoThing(elem, this._vaData.locations, this._vaData.destinations, this.settings);

    this.videoThing.on('location_reached', (id) => {
      bus.$emit('location_reached', id, this.videoThing.history);
    });

    this.videoThing.on('location_left', (id) => {
      bus.$emit('location_left', id, this.videoThing.history);
    });

    this.videoThing.on('custom_button_clicked', (e, item) => {
      bus.$emit('show_content', item.target_content.id);
    });

    this.videoThing.on('menu_button_clicked', (e, item) => {
      console.info(`You clicked menu button`, e, item.menu_data);
      // INFO: use e if you want to show dropdownm in button (e.target)
      bus.$emit('show_button_menu', e, item.menu_data);
    });

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.videothing {

    .controls {

        .button {
            @extend .uk-box-shadow-medium;
            padding: 0.5em 1em;
            background-color: var(--muted-color);
            user-select: none;
            &[data-type="hotspot"] {

                background-color: var(--primary-color);
                color: #fff;

                &:hover {
                    background-color: var(--primary-color);
                    filter: brightness(110%);
                }
            }

            &[data-type="navigation"] {
                background-color: var(--secondary-color);
                color: #fff;

                &:hover {
                    background-color: var(--secondary-color);
                    filter: brightness(110%);
                }
            }

            @media (max-width: $breakpoint-medium) {
                height: 35px;
                width: 35px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;

                .button__text {
                    display: none;
                }

                &:after {
                    content: '+';
                    font-size: 20px;
                    display: inline-block;

                }
            }

        }

    }

}
</style>
