<template>
<div>

  <button class="vm-modal__close" @click="$emit('close')" :title="strings.close">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>

  <div class="uk-box-shadow-medium uk-margin-bottom card">
    <div class="uk-background-muted uk-padding">

      <h1 v-if="title" v-html="title"></h1>
      <span v-if="subtitle" v-html="subtitle"></span>
      <div v-if="body">
        <vue-markdown>

          {{body}}
        </vue-markdown>
      </div>

      <LinkList :links="link_list"></LinkList>
    </div>
  </div>

  <Slide class="uk-background-default uk-box-shadow-medium uk-margin" v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
  <CallToContact :referer="title"></CallToContact>

</div>
</template>

<script>
import Slide from './Slide.vue'
import LinkList from './LinkList.vue';
import CallToContact from './CallToContact.vue';

// optional style for arrows & dots

export default {
  name: 'ModalDefault_mobile',
  data: function() {
    return {
      strings: this._vaData.strings,
    };
  },
  props: {
    title: String,
    subtitle: String,
    body: String,
    slides: Array,
    link_list: Array
  },
  components: {
    Slide,
    LinkList,
    CallToContact

  },
  methods: {
    loadIframe() {

    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
