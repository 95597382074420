import Vue from 'vue'
import App from './App.vue'
import VModal from 'vue-js-modal'
import Notifications from 'vue-notification'
import VueFriendlyIframe from 'vue-friendly-iframe';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Meta from 'vue-meta';

import {
  install as VueMarkdown
} from "vue-markdown/src/build";
UIkit.use(Icons);
Vue.use(VueMarkdown);
Vue.use(VModal)
Vue.use(Notifications)
Vue.use(VueFriendlyIframe);
Vue.use(Meta)
Vue.config.productionTip = false

// _vaData is filled after initial ajax-calls
// based on: https://stackoverflow.com/a/43193455/1308363

const _vaData = new Vue();

_vaData.install = function () {
  Object.defineProperty(Vue.prototype, '_vaData', {
    get() {
      return _vaData
    }
  })
}

Vue.use(_vaData);
_vaData.apiBase = process.env.VUE_APP_API_BASE;

// global event bus
// based on: https://blog.logrocket.com/using-event-bus-in-vue-js-to-pass-data-between-components/
export const bus = new Vue();

new Vue({
    render: h => h(App)
  })
  .$mount('#app')