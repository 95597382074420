<template>
<div class="uk-position-relative slide">


  <div v-if="slide.title || slide.subtitle" class="uk-padding header">
    <h2 v-if="slide.title" v-html="slide.title"></h2>
    <span v-if="slide.subtitle" v-html="slide.subtitle"></span>
  </div>

  <div v-if="!slide.src" class="media">
    <!-- Fallback -->
    <img src="fallback.jpg" alt="Asset not found" />
  </div>

  <template v-else>

    <div v-if="slide.type==='video'" class="media">
      <video class="uk-width-1-1" controls :poster="_vaData.apiBase + slide.poster" :src="_vaData.apiBase + slide.src"></video>
    </div>

    <div v-if="slide.type==='image'" class="media">
      <img :src="_vaData.apiBase + slide.src" :alt="slide.title">
    </div>

    <div v-if="slide.type==='iframe'" class="media">
      <div class="vx-ratio-box vx-ratio-box-16-9 uk-background-tertiary">
        <vue-friendly-iframe :src="slide.src" :allow="'fullscreen'"></vue-friendly-iframe>
      </div>
    </div>

    <div v-if="slide.caption" class="caption"><span>{{slide.caption}}</span></div>

    <div v-if="slide.body" class="uk-padding body">
      <vue-markdown>{{slide.body}}</vue-markdown>
    </div>
  </template>
</div>
</template>

<script>
export default {
  name: 'Slide',
  props: {
    slide: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 0.5em 1em;
    font-size: 11px;
    line-height: 1.5;
    // color: #fff;
    span {
        position: relative;
        z-index: 1;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        right: 0;
        background-color: #fff;
        opacity: 0.5;
    }
}
</style>
