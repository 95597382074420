<template>
<div class="uk-padding uk-background-default uk-box-shadow-medium" v-if="form">
  <button class="vm-modal__close" @click="$emit('close')">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>
  <div>
    <h4 class="title">{{form.title}}</h4>
    <p v-if="form.description">{{form.description}}</p>
  </div>

  <form class="uk-margin-top" ref="form">

    <fieldset class="uk-fieldset">

      <template v-if="form.contacts.length > 1">
        <div>
          <div class="uk-width-1-1">
            <select class="uk-input uk-select" name="contact" v-model="formData.contact" required>
              <option value="" disabled>{{form.select_contact}}</option>
              <option v-for="contact in form.contacts" :value="contact.email" :key="contact.id">{{contact.title}}</option>
            </select>
          </div>
        </div>
      </template>

      <template v-else-if="form.contacts.length === 0">
        No recipients defined.
      </template>



      <div class="uk-grid-small uk-margin-small uk-grid" uk-grid="uk-grid">
        <div class="uk-width-1-2@s">
          <input class="uk-input" v-model="formData.first_name" type="text" name="firstName" value="" :placeholder="first_name" required="required">
        </div>
        <div class="uk-width-1-2@s">
          <input class="uk-input" v-model="formData.last_name" type="text" name="lastName" value="" :placeholder="last_name" required="required">
        </div>
      </div>

      <div class="uk-margin-small">
        <input class="uk-input" v-model="formData.company" type="text" name="company" value="" :placeholder="company">
      </div>

      <div class="uk-grid-small uk-margin-small uk-grid" uk-grid="uk-grid">
        <div class="uk-width-1-2@s required">
          <input class="uk-input" v-model="formData.email" type="email" name="email" value="" :placeholder="email" required="required">
        </div>

        <div class="uk-width-1-2@s">
          <input class="uk-input" v-model="formData.phone" type="tel" name="phone" value="" :placeholder="phone">
        </div>
      </div>

      <div class="uk-margin-small required">
        <input class="uk-input" v-model="formData.subject" type="text" name="subject" value="" :placeholder="subject" :readonly="this.form.referer">
        <!-- :readonly="referer" -->
      </div>

      <div class="uk-margin-small required">
        <textarea class="uk-textarea " rows="5" v-model="formData.message" name="message" :placeholder="message"></textarea>
      </div>
      <hr>
      <div v-if="form.needs_confirmation" class="conformCheckbox uk-margin-small required">
        <input type="checkbox" name="mailCheckbox" value="check" required="required">
        {{form.confirmation_text}}
      </div>
      <div class="uk-margin-top uk-flex-right uk-grid" uk-grid="uk-grid">
        <button @click="reset" class="uk-button uk-button-default uk-margin-small-right" type="button">{{form.cancel}}</button>
        <!-- <input class="submit uk-button uk-button-primary" :disabled="sending" type="submit" :value="form.submit"> -->
        <button class="submit uk-button uk-button-primary" :disabled="sending" v-on:click="send">
          <template v-if="sending">
            <div uk-spinner="ratio: .5"></div>
          </template>
          <template v-else>
            {{form.submit}}
          </template>
        </button>

      </div>

    </fieldset>

  </form>
</div>
</template>


<script>
export default {
  name: 'ContactForm',
  props: {
    form: Object,
    strings: Object
  },
  data: function() {

    return {
      position: this.pos,
      sending: false,
      formData: {
        contact: '',
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        phone: '',
        subject: this.form.referer ? `${this.strings.i_want_to_know_more_about} ${this.form.referer}` : '',
        message: '',
        agree: false
      }
    }
  },
  mounted: function() {
    if (this.$props.form.contacts.length === 1) {
      this.formData.contact = this.$props.form.contacts[0].email
    }
  },
  computed: {
    first_name: function() {
      return this.form.fields.find(f => f.uid === 'first_name').label;
    },
    last_name: function() {
      return this.form.fields.find(f => f.uid === 'last_name').label;
    },
    company: function() {
      return this.form.fields.find(f => f.uid === 'company').label;
    },
    phone: function() {
      return this.form.fields.find(f => f.uid === 'phone').label;
    },
    email: function() {
      return this.form.fields.find(f => f.uid === 'email').label;
    },
    subject: function() {
      return this.form.fields.find(f => f.uid === 'subject').label;
    },
    message: function() {
      return this.form.fields.find(f => f.uid === 'message').label;
    }
  },

  methods: {

    send() {

      if (!this.$refs.form.reportValidity())
        return;

      const apiBase = process.env.VUE_APP_API_BASE;
      this.sending = true;
      fetch(apiBase + 'mail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: this.formData.contact,
            replyTo: this.formData.email,
            subject: this.formData.subject,
            html: `
            <p>
              <strong>Sender:</strong> ${this.formData.first_name} ${this.formData.last_name}<br/>
              ${(this.form.referer) ? '<strong>Referer:</strong> ' + this.form.referer + '<br/>' : ''},
              <strong>Company:</strong> ${this.formData.company}<br/>
              <strong>Phone:</strong> ${this.formData.phone}<br/>
              <strong>E-Mail:</strong> ${this.formData.email}
            <p>
            <h3>${this.formData.subject}</h3>
            <p>${this.formData.message}</p>
            `
          })
        })
        .then(response => response.json())
        .then(response => {

          if (response.statusCode === 200) {
            this.$notify({
              type: 'success',
              title: this.form.success_title,
              text: this.form.success_message
            });
            this.reset()
          } else {
            throw new Error(response.error);
          }

        })
        .catch((error) => {

          console.error('Error:', error);
          this.$notify({
            type: 'error',
            title: this.form.error_title,
            text: this.form.error_message
          });
        })
        .then(() => {
          this.sending = false;
        });
    },
    reset() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped lang="scss">
.title {
    @extend h2;
}

.submit {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
