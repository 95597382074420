<template>
<div class="videoapp">
  <notifications position="bottom right" />

  <div v-if="isError" class="videoapp__error">
    <!-- ERROR -->
    <span class="emoji">😑</span>
    <h1>We are sorry!<br>
      Something went wrong.</h1>
    <div class="uk-alert-danger" uk-alert>
      {{isError}}
    </div>
    <p>If this error persists, please contact us.</p>
    <p>
      <a href="mailto:apps@visionsbox.de">apps@visionsbox.de</a>
      <br>
      <a href="tel:+49 7803 9789030">+49 7803 9789030</a>
    </p>
  </div>
  <div v-else-if="isLoading" class="videoapp__loading">
    <!-- LOADER -->
    <div>
      <i uk-spinner="ratio: 3"></i>
    </div>
    <p>Loading ...</p>
  </div>
  <template v-else>
    <div class="videoapp__main" id="app">

      <!-- APP -->
      <div id="page-wrap">
        <header class="videoapp__header">
          <Title :showBackBtn="showBackBtn"></Title>
          <a class="videoapp__logo" href="/" :title="strings.app_title" v-if="settings.logo">
            <img :src="_vaData.apiBase + settings.logo" :alt="strings.app_title">
          </a>
        </header>
        <Content />
        <ButtonMenu />
        <div class="videoapp__videothing-wrap">
          <VideoThing :settings="vtSettings" />
          <ContactMenu class="videoapp__contacts" />
        </div>
        <MobileTitle :showBackBtn="showBackBtn"></MobileTitle>

        <footer class="videoapp__footer">
          <div uk-grid class="uk-flex-bottom">
            <div class="uk-flex-1">
              <div uk-grid class="uk-grid-small">
                <UkNavDropdown v-if="locationsMenuEntries.length > 1" :entries="locationsMenuEntries" :title="strings.locations" @onEntryClick="onDropDownNavEntryClick" />
                <!-- <UkNavDropdown v-if="contentMenuEntries.length  > 0" :entries="contentMenuEntries" :title="strings.contents" @onEntryClick="onDropDownNavEntryClick" /> -->
              </div>
            </div>
            <div>
              <div uk-grid class="uk-grid-small">
                <transition name="fade" enter-active-class="fadeInRight" leave-active-class="fadeOutRight">
                  <UkNavDropdown v-if="locationHotspots.length  > 0" :pos="'top-right'" :entries="locationHotspots" :title="strings.contents_at_location" @onEntryClick="onDropDownNavEntryClick" />
                </transition>
              </div>
            </div>
          </div>

        </footer>
      </div>
    </div>
    <Offcanvas></Offcanvas>

  </template>

</div>
</template>

<script>
import VideoThing from './components/videoThing/VideoThing.vue';
import UkNavDropdown from './components/UkNavDropdown.vue';
import Offcanvas from './components/Offcanvas.vue';
import Content from './components/content/index.vue';
import ButtonMenu from './components/buttonMenu/index.vue';
import Title from './components/Title.vue';
import MobileTitle from './components/MobileTitle.vue';
import ContactMenu from './components/ContactMenu.vue';


import {
  bus
} from './main'


export default {
  name: 'App',
  components: {
    VideoThing,
    UkNavDropdown,
    Content,
    ButtonMenu,
    Title,
    MobileTitle,

    ContactMenu,
    Offcanvas
  },
  metaInfo() {

    return {
      title: this.strings ? this.strings.app_title : 'Loading ...',
      meta: [{
        name: 'desctiption',
        content: this.strings ? this.strings.description : ''
      }]
    }
  },
  data: function() {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      isLoading: true,
      isError: false,
      vtSettings: false,
      locationTitle: false,
      locationHotspots: false,
      showBackBtn: false
    };
  },
  computed: {
    contentMenuEntries: function() {
      return this._vaData.contents ? this._vaData.contents.filter(c => c.show_in_menu) : false;
    },
    locationsMenuEntries: function() {
      return this._vaData.locations ? this._vaData.locations.filter(l => l.show_in_menu) : false;
    }
  },
  watch: {
    // https://stackoverflow.com/a/46331968/1308363
    'settings.show_hotspots': {
      handler(val) {
        if (val) {
          bus.$emit('show_hotspots');
        } else {
          bus.$emit('hide_hotspots');
        }
      }
    },
    'settings.show_navigation': {
      handler(val) {
        if (val) {
          bus.$emit('show_navigation');
        } else {
          bus.$emit('hide_navigation');
        }
      }
    },
    'settings.show_menus': {
      handler(val) {
        if (val) {
          bus.$emit('show_menus');
        } else {
          bus.$emit('hide_menus');
        }
      }
    }
  },
  methods: {
    async fetchData() {

      this.isLoading = true;
      const availableLanguages = ['en', 'cn'];

      console.log('🔄 Loading data ...')

      const params = new URLSearchParams(window.location.search);
      const langParameter = params.get('lang');
      let lang = 'en';

      if (langParameter) {
        lang = (availableLanguages.indexOf(langParameter) > -1) ? langParameter : lang;
      } else {
        // try user lang
        var userLang = navigator.language || navigator.userLanguage;
        userLang = userLang.split('-')[0];
        lang = (availableLanguages.indexOf(userLang) > -1) ? userLang : lang;
      }

      // const lang_suffix = '/' + lang;


      console.log('🏳️‍🌈 Language: ' + lang)
      // set document lang (html attribute)
      document.documentElement.lang = lang.split('-')[0];
      this._vaData.currentLanguage = lang;


      await Promise.all([
          // fetch(this._vaData.apiBase + '/locations' + lang_suffix),
          // fetch(this._vaData.apiBase + '/destinations'),
          // fetch(this._vaData.apiBase + '/settings'),
          // fetch(this._vaData.apiBase + '/contents' + lang_suffix),
          // fetch(this._vaData.apiBase + '/strings' + lang_suffix)
          fetch('locations-' + lang + '.json'),
          fetch('destinations.json'),
          fetch('settings.json'),
          fetch('contents-' + lang + '.json'),
          fetch('strings-' + lang + '.json')
        ])
        .then(data => {

          let failedRequests = data.filter(d => d.ok === false);

          if (failedRequests.length) {
            let error = 'Some API calls failed.';
            throw Error(error)
          }

          return data
        })
        .then((data) => {
          return Promise.all(data.map(d => d.json()))
        })
        .then(data => {

          this._vaData.locations = this.locations = data[0];
          this._vaData.destinations = this.destinations = data[1];
          this._vaData.settings = this.settings = data[2];
          this._vaData.contents = this.contents = data[3];
          this._vaData.strings = this.strings = data[4];

          console.log('👨🏼‍🔧 Settings', this._vaData.settings)
          console.log('🏝️ Locations: ', this._vaData.locations)
          console.log('✈️ Destinations: ', this._vaData.destinations)
          console.log('📚 Contents: ', this._vaData.contents)
          console.log('📄 Strings: ', this._vaData.strings)

          if (!this.locations.length) {
            // this.isError = 'No location found!';
            throw new Error('No location found!')
          }

          this.init();

        })
        .catch(err => {
          // api not responding
          this.isError = err;

        }).finally(() => {
          this.isLoading = false;
        })

    },
    init() {

      const screensaverSetting = this.settings.screensaver;
      console.log('📽️ Starting videoThing')

      this.vtSettings = {
        startLocation: this.settings.start_location ? this.settings.start_location : this._vaData.locations[0].id,
        preloadDestinationsAtStart: false,
        assetBase: this._vaData.apiBase,
        screensaver: {
          enabled: screensaverSetting.enbaled ? true : false,
          timeout: screensaverSetting.timeout ? screensaverSetting.timeout : 120,
          cue: screensaverSetting.locations && screensaverSetting.locations.length > 1 ? screensaverSetting.locations : [],
          clearEvents: ['mousemove', 'touchstart'],
          locationTime: screensaverSetting.time_at_location ? screensaverSetting.time_at_location : 7,
        },

        transitionOverlay: {
          color: '#fff',
          transitionDuration: 3000,
        },
      }

      this.setCssVars();
    },
    setCssVars() {
      document.querySelector('body').style.setProperty('--primary-color', this.settings.primary_color);
      document.querySelector('body').style.setProperty('--secondary-color', this.settings.secondary_color);
      document.querySelector('body').style.setProperty('--tertiary-color', this.settings.tertiary_color);
      document.querySelector('body').style.setProperty('--muted-color', this.settings.muted_color);
    },
    onDropDownNavEntryClick(entry) {
      switch (entry.type) {
        case 'default':
          bus.$emit('show_content', entry.id);
          break;
        case 'hotspot':
          bus.$emit('show_content', entry.target_content.id);
          break;
        case 'menu':
          bus.$emit('show_button_menu', null, entry.menu_data);
          break;
        default:
          bus.$emit('go_to_location', entry.id);
          break;
      }
    }
  },
  async created() {

    bus.$on('location_reached', (id, history) => {
      const location = this._vaData.locations.find(l => l.id === id)
      console.info(`➡️ You reached location: ${location.title} (ID: ${id})`, location);
      this.locationTitle = location.title
      // menus and hotspots of current location
      this.locationHotspots = location.buttons.filter(b => b.type === 'hotspot' || b.type === 'menu');

      setTimeout(() => {
        this.showBackBtn = history.length > 1 && location.show_back_btn;
      }, 1)

    })

    bus.$on('location_left', (id) => {
      const location = this._vaData.locations.find(l => l.id === id)
      console.info(`⬅️ You left location: ${location.title} (ID: ${id})`);
      this.locationHotspots = false;
      this.showBackBtn = false;
    })

    await this.fetchData();

    const startContent = this.settings.start_content;
    if (startContent) {
      bus.$emit('show_content', startContent);
    }
  },

  mounted() {

  }
}
</script>

<style lang="scss">
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";
@import './videoapp.scss';
</style>
