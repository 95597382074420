<template>
<div>


  <button class="vm-modal__close" @click="$emit('close')" :title="strings.close">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>

  <button class="c-va_modal__expand" @click="requestFullscreen()">
    <span uk-icon="icon: expand; ratio: 1.5"></span>
  </button>

  <div class="c-va_modal__container">


    <div class="uk-width-1-3 uk-margin-small-right uk-box-shadow-medium uk-flex uk-flex-column">
      <div class="uk-padding uk-background-muted uk-flex-1">
        <h1 v-if="title" v-html="title"></h1>
        <span v-if="subtitle" v-html="subtitle"></span>
        <div v-if="body">
          <vue-markdown>

            {{body}}
          </vue-markdown>
        </div>
        <LinkList :links="link_list"></LinkList>
      </div>

      <CallToContact :referer="title"></CallToContact>

    </div>

    <div class="uk-width-expand uk-background-default uk-box-shadow-medium c-va_modal__main">

      <VueSlickCarousel v-bind="slickSettings" @beforeChange="beforeChangeCarousel" @afterChange="afterChangeCarousel" @init="onInitCarousel" ref="carousel">
        <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
      </VueSlickCarousel>
    </div>
  </div>

</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Slide from './Slide.vue'
import LinkList from './LinkList.vue'
import CallToContact from './CallToContact.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


// optional style for arrows & dots
export default {
  name: 'ModalDefault_desktop',
  data: function() {
    return {
      strings: this._vaData.strings,
      slickSettings: {
        "dots": true,
        "arrows": true
      }
    };
  },
  props: {
    title: String,
    subtitle: String,
    body: String,
    slides: Array,
    link_list: Array
  },
  components: {
    VueSlickCarousel,
    Slide,
    LinkList,
    CallToContact
  },
  created() {

    document.addEventListener("fullscreenchange", this.onFullscreenChange, false);
    document.addEventListener("mozfullscreenchange", this.onFullscreenChange, false);
    document.addEventListener("webkitfullscreenchange", this.onFullscreenChange, false);
    document.addEventListener("msfullscreenchange", this.onFullscreenChange, false);


  },
  mounted() {
    document.querySelectorAll('.c-va_modal__container video').forEach(($video) => {

      // hide controls of all videos
      $video.controls = "";
    });
    const $videos = document.querySelector('.slick-current video');
    if ($videos)
      $videos.controls = "controls"
  },
  methods: {

    requestFullscreen() {
      this.$fullScreenEl = this.$refs.carousel.$el;

      setTimeout(() => {
        this.$fullScreenEl.requestFullscreen();
      })
    },
    onFullscreenChange() {

      const $modalContainer = document.querySelector('.c-va_modal__container')
      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement && !document.fullScreen) {

        // leave
        $modalContainer.setAttribute('uk-height-match', 'uk-height-match')

        // remove fullscreen btns
        this.$fsShrinkBtn.remove()
        this.$fsNextBtn.remove()
        this.$fsPrevBtn.remove()
      } else {


        //enter
        $modalContainer.removeAttribute('uk-height-match')

        // add shrink button
        this.$fsShrinkBtn = document.createElement('button')
        this.$fsShrinkBtn.classList.add('c-va_modal__fs-shrink')
        this.$fsShrinkBtn.innerHTML = ` <span uk-icon="icon: shrink; ratio: 1.5"></span>`;
        this.$fsShrinkBtn.addEventListener('click', () => {
          document.exitFullscreen();
        });
        this.$fullScreenEl.append(this.$fsShrinkBtn);

        // add next btn
        this.$fsNextBtn = document.createElement('button')
        this.$fsNextBtn.classList.add('c-va_modal__fs-next')
        this.$fsNextBtn.innerHTML = ` <span uk-icon="icon:  chevron-right; ratio: 1.5"></span>`;
        this.$fsNextBtn.addEventListener('click', () => {
          this.$refs.carousel.next()
        });
        this.$fullScreenEl.append(this.$fsNextBtn);

        // add prev btn
        this.$fsPrevBtn = document.createElement('button')
        this.$fsPrevBtn.classList.add('c-va_modal__fs-prev')
        this.$fsPrevBtn.innerHTML = ` <span uk-icon="icon:  chevron-left; ratio: 1.5"></span>`;
        this.$fsPrevBtn.addEventListener('click', () => {
          this.$refs.carousel.prev()
        });
        this.$fullScreenEl.append(this.$fsPrevBtn);


      }
    },

    onInitCarousel() {


    },
    afterChangeCarousel() {


      const $currentSlide = this.$refs.carousel.$el.querySelector('.slick-current');
      const $video = $currentSlide.querySelector('video');
      if ($video) {
        // show controls
        $video.controls = "controls";
      }


    },
    beforeChangeCarousel() {

      const $currentSlide = this.$refs.carousel.$el.querySelector('.slick-current');
      const $video = $currentSlide.querySelector('video');
      if ($video) {
        $video.pause();
        // hide controls
        $video.controls = "";


      }
      //
      //
      // const $iframe = $currentSlide.querySelector('iframe');
      //
      // if ($iframe && !$iframe.getAttribute('src')) {
      //   console.log($iframe)
      //   // IDEA: https://officert.github.io/vue-friendly-iframe/#/install
      //   $iframe.setAttribute('src', $iframe.dataset.src);
      // }

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
