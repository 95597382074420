<template>
<div class="callToContact">
  <button class="cta" @click="showContactForm()" :title="strings.get_info_cta">
    <span uk-icon="icon: mail" class="uk-margin-right"></span><span>{{strings.get_info_cta}}</span>
  </button>
</div>
</template>

<script>
import ContactForm from '../ContactForm.vue'
export default {
  name: 'CallToContact',
  props: {
    referer: String
  },
  data: function() {
    return {
      form: this._vaData.strings.forms.find(f => f.form_id === 'default_contact_form'),
      strings: this._vaData.strings
    }
  },
  components: {

  },
  methods: {
    showContactForm() {

      this.form.referer = this.referer;
      this.$modal.show(
        ContactForm, {
          form: this.form,
          strings: this._vaData.strings
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.callToContact {

    @extend .uk-background-default;
    @extend .uk-padding;
}
.cta {
    @extend .uk-button, .uk-button-primary, .uk-button-large;
    line-height: 1.4;
    width: 100%;
    padding: 1em;
    font-size: 16px;
    font-weight: bold;

}
</style>
