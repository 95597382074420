<script>
import {
  bus
} from '../../main'

import ModalDefault_desktop from './ModalDefault_desktop.vue'
import ModalDefault_mobile from './ModalDefault_mobile.vue'
import ModalMedia from './ModalMedia.vue'

export default {
  name: 'Content',
  props: {

  },
  methods: {
    show(data) {

      const isSmall = window.innerWidth < 960;
      let component = false;

      switch (data.type) {
        case 'media':
          component = ModalMedia;
          break;
        default:
          // Sidebar + (Slideshow incl. Body)
          component = isSmall ? ModalDefault_mobile : ModalDefault_desktop;

      }

      this.$modal.show(
        component, data, {
          name: 'content-modal',
          classes: `c-va_modal c-va_modal--${data.type} ${isSmall ? 'c-va_modal--isSmall' : ''}`,
          height: isSmall ? 'auto' : 'auto',
          width: isSmall ? '100%' : '100%',
          scrollable: true,
          shiftY: .3

        }
      )
    }
  },
  created() {
    bus.$on('show_content', (key) => {


      const data = this._vaData.contents.find(d => {
        return d.key === key
      });

      if (!data) {
        this.$notify({
          type: 'error',
          title: 'Content with key ' + key + ' not found!',
          text: 'Perhaps it has been deleted.'
        });
        return false;
      }


      this.show(data)
    })
  },
  render() {
    return false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import 'index.scss';
</style>
