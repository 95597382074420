<template>
<div class="uk-position-center-left uk-padding">

  <div class="contacts">

    <div v-for="(link, i) in links" :key="i" class="link" :class="{ 'link--open': i === openLink}">

      <template v-if="isPhoneLink(link.href)">
        <a class="link__text" v-if="isPhoneLink(link.href)" :href="link.href">
          <span>{{link.title}}</span>
        </a>
        <div :title="link.title" class="link__icon" @click="toggleLink(i)">
          <i v-if="link.icon" :style="{ 'background-image': 'url('  + _vaData.apiBase + link.icon + ')' }"></i>
        </div>
      </template>
      <template v-else>
        <a class="link__icon" :href="link.href" :title="link.title" :target="'_' + link.target">
          <i v-if="link.icon" :style="{ 'background-image': 'url(' + _vaData.apiBase  + link.icon + ')' }"></i>
        </a>
      </template>
    </div>

    <div v-if="form" class="link">
      <a class="link__icon" @click="showContactForm" :title="form.title">
        <i :style="{ 'background-image': 'url(./contact_form_icon.svg)' }"></i>
      </a>
    </div>

  </div>

</div>
</template>
<script>
import ContactForm from './ContactForm.vue'
import {
  bus
} from '../main'
export default {
  name: 'ContactMenu',
  data: function() {

    const form = this._vaData.strings.forms.find(f => f.form_id === 'default_contact_form');

    return {
      openLink: null,
      links: this._vaData.strings.contact_links,
      form: form,
    }
  },

  methods: {
    isPhoneLink: function(href) {
      return (href.indexOf('tel:') > -1) ? href.replace('tel:', '') : false;
    },
    toggleLink: function(i) {
      this.openLink = (this.openLink) ? null : i;

    },
    showContactForm() {

      this.form.referer = null;

      this.$modal.show(
        ContactForm, {
          form: this.form,
          strings: this._vaData.strings
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true
        }
      )
    }
  },
  created() {
    bus.$on('show_contact_form', () => {
      this.showContactForm()
    })

    document.querySelector('.videoapp').addEventListener('click', (e) => {
      // click wherever to close open link
      if (e.path.indexOf(document.querySelector('.link--open')) === -1) {
        this.openLink = null;
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.contacts {
    --btn-size: 50px;
    width: var(--btn-size);
    display: flex;
    flex-direction: column;
}

.link {

    display: flex;
    margin: 0.2em 0;
    height: var(--btn-size);

    &.link--open {
        .link__text {
            max-width: 200px;
        }
    }

}
.link__icon {
    @extend .uk-button;
    @extend .uk-button-primary;
    color: #fff;
    display: block;
    height: var(--btn-size);
    width: var(--btn-size);
    padding: 0;
    margin: 0;
    position: relative;
    flex-shrink: 0;
    border: 0;
    border-left: 2px solid var(--secondary-color);

    i {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        filter: brightness(0) invert(1);
    };

}

.link__text {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    height: 100%;
    max-width: 0;
    flex-shrink: 0;
    display: flex;
    overflow: hidden;
    transition: all 0.5s;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    span {
        margin: 0 1em;
    }

    &:hover {
        color: var(--primary-color);
        text-decoration: none;
    }
}
</style>
