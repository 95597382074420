<template>
<div class="uk-nav-dropdown">
  <button class="uk-button uk-button-primary" type="button">{{title}}</button>
  <div :uk-dropdown="position ? ('pos:' + position) : ''">
    <ul class="uk-nav uk-dropdown-nav">
      <li v-for="entry in entries" :key="entry.id" @click="onEntryClick(entry)">
        <a href="#">{{ entry.title }}</a>
      </li>
    </ul>
  </div>
</div>
</template>


<script>
export default {
  name: 'UkNavDropdown',
  props: {
    title: String,
    entries: Array,
    pos: String
  },
  data: function() {
    return {
      position: this.pos
    }
  },
  mounted() {

  },
  methods: {
    onEntryClick(entry) {

      this.$emit('onEntryClick', entry);
    }
  }
}
</script>
